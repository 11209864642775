import { Button, Form, Container, Row, Col, Card, Toast } from "react-bootstrap";
import AppLayout from "../Layout/AppLayout";
import { useNavigate } from "react-router-dom";
import { store, useAppDispatch, useAppSelector } from "../store/store";
import { useContext, useEffect, useRef, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { updateTransId } from "../store/features/paymentSlice";
import { base3dSdkUrl, base3dSecureElavonScript, baseUrlElavonCheckoutScript, billingApiUrl } from "../Components/APIs/urls";
//import { UserLinkIdStatus } from "../App";
import { updateStepComplete, updateStepInProcess } from "../store/features/stepSliceProduction";
import { persistStore } from "redux-persist";
import { continueFalse } from "../store/features/continueSteps";
import "../Styles/payment.css";
import stateData from "../Data/stateAbbreviation.json"
import { UserContext } from "../Types/UserContext";
import {auth} from '../FirebaseConfig'
import { formPaymentProps } from "../Types/formPaymentType";
import ValidatorPayment, { card_types, isPaymentValid } from "../Components/ValidatorPayment"
import { onAuthStateChanged } from "firebase/auth";
import Error401 from "./Error401";
import { useAuthState } from "react-firebase-hooks/auth";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import ModalComponent from "../Components/Modal/ModalComponent";
import { resetAuthObj } from "../store/features/authSlice";

// ----PAYMENT AUTH COMPONENT CALLS-----
// 1. CALL 1 - UPDATE LINKSTATUS TO IN PROGRESS ON LOAD
// 2. CALL 2 - SUBMIT PAYMENT INFO TO CONVERGE
// --- IF PAYMENT APPROVED AND PAYMENT SOURCE == PATIENT ---
// 3. CALL 3 - SEND TRANS ID TO BACKEND
// 4. CALL 4 - UPDATE LINKSTATUS TO COMPLETE
// --- IF PAYMENT APPROVED AND PAYMENT SOURCE == PAYOR ---
// 4. CALL 4 - UPDATE DOCGEN SIGNED STATUS TO BACKEND
// 5. CALL 5 - UPDATE LINKSTATUS TO COMPLETE

export default function PaymnentAuth() {
  //USE CONTEXT FOR LINKID
  //const { userLinkId } = useContext(UserLinkIdStatus);

  const { userLinkId } = useContext(UserContext)

  const cookies = new Cookies();

  const {t, i18n} = useTranslation();
  const lng = navigator.language;

  const API_KEY = process.env.REACT_APP_API_KEY + "";

  console.log("NEW DEPLOYMENT 3");

  //REDUX GETTERS
  const patientObj = useAppSelector((state) => state.patient.patientObj[0]);
  const stepsProductionObj = useAppSelector((state) => state.stepProduction.stepsProductionObj[0]);
  const isContinue = useAppSelector((state) => state.continue.isContinue);
  const authObj = useAppSelector((state) => state.auth.authObj);
  const linkObj = useAppSelector((state) => state.link.linkObj);
  
  //USESTATES
  const [patient, setPatient] = useState();
  const [cardData, setCardData] = useState({
    FullName: "",
    CardNumber: "",
    ExpirationDate: "",
    Cvc: "",
    BillingZip: "",
    BillingAddress: "",
    BillingCity: "",
    BillingState: "",
  });

  const [payorEmail, setPayorEmail] = useState("");
  const [message, setMessage] = useState();
  const [messageStatus, setMessageStatus] = useState();
  const [isError, setIsError] = useState(false);
  const [isDecline, setIsDecline] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isSigned, setIsSigned] = useState();
  const [steps, setSteps] = useState();
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);

  //USEREFS
  const isFirstRender = useRef(true);
  const transId = useRef("")
  const isApprovedRef = useRef(false);
  const isDeclinedRef = useRef(false)
  const isErrorRef = useRef(false);
  const initElavonToken = useRef("");
  const isClicked = useRef(false);
  const TokenValid = useRef(true);

  //Modal Methods
  const handleClose = () => {
    console.log("inside handle Close credit card info", cardData.CardNumber);
    console.log("inside handle Close show", show);
    isClicked.current = false;
    setShow(false);
  }
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false);
  const [isAgreed, setIsAgreed] = useState(true);
  const [user] = useAuthState(auth);

  const s = '!"#$%&\'()*+,-./0123456789:;<=>?@ABsCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^_`abcdefghijklmnopqrstuvwxyz{|}~';

  const alphaUpper = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"]
  const specialChar = ["?","@", "!", "#", "$", "%", "&", "*", "+", ",", ".", "-", "(", ")", "=", "{", "}", "|", "`", "~"]
  const alphaLower = ["a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z"]

  const symbolsNotAllowed = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z",
  "a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z","?","@",
  "!", "#", "$", "%", "&", "*", "+", ",", ".", "-", "(", ")", "=", "{", "}", "|", "`", "~", ";", ":", "<", ">", "^", "_", " ", "/"];

  //VARIABLES
  console.log("Patient OBJECT", patientObj)
  console.log("Patient email in payAuth", patientObj.email);
  const personToPay = patientObj.paymentSource.toString() === '1' ? t('patientInfoRadioLabelOptionPatient') : t('patientInfoRadioLabelOptionPayor');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const cost = patientObj.paymentAmount

  //DATE OBJECT
  const myDate = new Date();
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "numeric",
  };
  const date = myDate.toLocaleDateString("en-US", options);

  console.log("date", date);
  console.log("exp date", cardData.ExpirationDate)

  //GET ESIGN EVENT
  function eventHandler(e) {
    if (e.origin === "https://systemprocess.jotform.com") {
      console.log("signed document", e.signedDocumentURL)
      console.log("origin matched")
      console.log("data", e.data)
      console.log("data action", e.data.action);
      if (e.data.action === "submission-completed") {
        console.log("event message after signature", e);
        console.log("form signed", e.data.action);
        setIsSigned(true);
      }
    }
  }

  const res = isPaymentValid(card_types, cardData.CardNumber + "")

//   const handleLogout = () => {
//     auth.signOut().then(() => {
//     console.log("successfully logged out");
//   })
// }

const handleLogout = async () => {
  auth.signOut().then(() => {
    console.log("Token expired. Get new one")
    cookies.remove("jwt_authorization");
    navigate("/auth")
  }).catch((err) => {
    console.log(err);
  })
}

const getAuthToken = async () => {

  //GET CURRENT TIME
  const currentTime = new Date();
  console.log("Current Time", currentTime);

  console.log("Auth OBJ", authObj)
  const dateFormatted = new Date(Date.parse(authObj.exp_Date))
  console.log("Auth OBJ date formatted", dateFormatted)

  //COMPARE IF TOKEN IS STILL VALID
  if(dateFormatted > currentTime) {
    console.log("current time inside Patient Contract", currentTime);
    console.log("Token not yet expired Inside Patient Contract")
  }
  else {
    TokenValid.current = false;
    console.log("Token expired inside patient contract!!")
    handleLogout();   
  }

}

const convergeLogApi = async (convergeResp) => {
  console.log("inside convergeLogFunction")
  console.log("parameter response obj from decline", convergeResp);
  console.log("link id", cookies.get('link_id'));
  console.log("LinkStepId", stepsProductionObj[stepsProductionObj.length - 1].linkStepId);
  console.log("still inside convergeLogFunction");

  try {
    const resp = await fetch(`${billingApiUrl}/api/linkpaymenthistory/${cookies.get('link_id')}/log`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "69420",
        "x-api-key": API_KEY,
        "Authorization": "Bearer" + " " + cookies.get('jwt_authorization')
      },
      body: JSON.stringify({
        "linkStepId": stepsProductionObj[stepsProductionObj.length - 1].linkStepId,
        "payload": JSON.stringify(convergeResp)
      })
    });
    const data = await resp.json();
    console.log("Data from convergeLogAPI", data);
    
  } catch (error) {
    console.log(error);
  }
}


  //USEEFFECT HAS 2 CALLS -> 1. FETCHDATA UPDATE STEP TO - IN PROGRESS & FETCHDATA APPROVED ONCE PAYMENT IS PROCESSED - SET TO COMPLETE
  useEffect(() => {
    getAuthToken();
    window.history.pushState(null, '', window.location.href);
    window.addEventListener("popstate", e => {
      window.history.pushState(null, '', window.location.href);
      console.log("EVENT IN BACK Payment Auth", e)
      //navigate(1);
      return () => {
        window.removeEventListener('popstate', (e) => {
          
        })
      }

    })
    console.log("This is the show useState for Modal", show);
    onAuthStateChanged(auth, (data) => {
      console.log("user", auth.currentUser);
      if(!data) {
        console.log("user data in payment auth", data);
        navigate("/auth")
      }
      else {
        console.log("patientobj in payauth useEff", patientObj)
        setSteps(stepsProductionObj)
        setPatient(patientObj)
        if(patientObj.paymentSource.toString() === "2") {
          setCardData({
            FullName: "",
            CardNumber: "",
            ExpirationDate: "",
            Cvc: "",
            BillingZip: "",
            BillingAddress: "",
            BillingCity: "",
            BillingState: "",
          })
        }
        else {
          setCardData({
            FullName: patientObj.firstName + " " + patientObj.lastName,
            CardNumber: "",
            ExpirationDate: "",
            Cvc: "",
            BillingZip: patientObj.zipCode,
            BillingAddress: patientObj.address,
            BillingCity: patientObj.city,
            BillingState: patientObj.state,
          })
          console.log("Billing State",cardData.BillingState)
        }
        const stepParams = new URLSearchParams();
        stepParams.append("linkId", cookies.get('link_id'));
        stepParams.append("linkStepId", stepsProductionObj[stepsProductionObj.length - 1].linkStepId);
        stepParams.append("LinkStepStatusId", "2");
        //**** CALL TO BACKEND API - UPDATE PATIENT INVOICE STEP IN BACKEND TO - IN PROGRESS 
        const fetchDataUpdate = async () => {
          console.log("Calling add payment auth in progress token valid", TokenValid.current)
          try {
            isFirstRender.current = false
            const resp = await fetch(`${billingApiUrl}/api/linksteps?` + stepParams.toString(), {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                "ngrok-skip-browser-warning": "69420",
                "x-api-key": API_KEY,
                "Authorization": "Bearer" + " " + cookies.get('jwt_authorization')
              },
            });
            if (resp.ok) {
              console.log("response okay payAuth update")
              dispatch(updateStepInProcess({routePath: stepsProductionObj[stepsProductionObj.length - 1].routePath, linkStepStatusId: 2}));
            } 
            else if(!resp.ok) {
              setLoading(false);
              //document.body.style.backgroundColor = "#551A8B";
              if(resp.statusText) {
                setError(resp.status + " " + resp.statusText)
              }
              else {
                const result = await resp.text()
                setError(resp.status + " " + result)
              }
            }
          } catch (error) {
            console.error(`Error fetching data: ${error.message}`);
            setLoading(false)
            navigate("*")
          } 
        };
        //FUNCTION CALL
  
        //PAYMENT GATEWAY SCRIPT CALLS
        const scriptCheckout = document.createElement("script");
        scriptCheckout.src = `${baseUrlElavonCheckoutScript}/Checkout.js`;
        scriptCheckout.async = true;
        document.body.appendChild(scriptCheckout);
        
        const scriptThreeD = document.createElement("script");
        scriptThreeD.src = `${base3dSecureElavonScript}`;
        scriptThreeD.async = true;
        document.body.appendChild(scriptThreeD);
  
        const updateTransId = async () => {
          console.log("Inside updateTransId")
          try {
              const resp = await fetch(`${billingApiUrl}/api/linkpaymenthistory`, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  "ngrok-skip-browser-warning": "69420",
                  "x-api-key": API_KEY,
                  "Authorization": "Bearer" + " " + cookies.get('jwt_authorization')
                },
                body: JSON.stringify({
                  //add full name & email payorFullName payorEmail
                  "linkId": cookies.get('link_id'),
                  "transactionId": transId.current,
                  "payorFullName": cardData.FullName,
                  "payorEmail": payorEmail
                })
              });
              if(!resp.ok) {
                setLoading(false);
                //document.body.style.backgroundColor = "#551A8B";
                if(resp.statusText) {
                  setError(resp.status + " " + resp.statusText)
                }
                else {
                  const result = await resp.text()
                  setError(resp.status + " " + result)
                }
              }
  
          } catch (error) {
              console.log("error caught", error);
  
          }
        }
  
        //FUNCTION TO CALL BACKEND API TO DOC GEN THE PAYOR AUTH
        //fetchPayorAuthSignedToDocGen
   
  
        const fetchDataApproved = async () => {
          console.log("Calling add payment auth complete token valid", TokenValid.current)
          try {
              console.log("Inside fetchDataApproved function - card has been processed and approved");
              updateTransId();
              const stepParams = new URLSearchParams();
              stepParams.append("linkId", cookies.get('link_id'));
              stepParams.append("linkStepId", stepsProductionObj[stepsProductionObj.length - 1].linkStepId);
              stepParams.append("LinkStepStatusId", "4");
              //**** CALL TO BACKEND API - UPDATE PATIENT ID STEP IN BACKEND TO - COMPLETE
              const resp = await fetch(`${billingApiUrl}/api/linksteps?` + stepParams.toString(), {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  "ngrok-skip-browser-warning": "69420",
                  "x-api-key": API_KEY,
                  "Authorization": "Bearer" + " " + cookies.get('jwt_authorization')
                },
              });
              if (resp.ok) {
                console.log("response okay in auth completed", resp);
                let persistor = persistStore(store);
                // 1. UPDATE REDUX STEP SLICE TO COMPLETE, SET LOADING FALSE, NAVIGATE TO NEXT STEP
                dispatch(updateStepComplete({ routePath: stepsProductionObj[stepsProductionObj.length - 1].routePath, linkStepStatusId: 4 }));
                persistor.pause();
                persistor.flush().then(() => {
                  return persistor.purge();
                }) 
                //handleLogout();
                console.log("Call made to api to update pay auth step complete and route to confirmation")
                navigate("/confirmation");
                setLoading(false);
              }
              else if(!resp.ok) {
                setLoading(false);
                //document.body.style.backgroundColor = "#551A8B";
                if(resp.statusText) {
                  setError(resp.status + " " + resp.statusText)
                }
                else {
                  const result = await resp.text()
                  setError(resp.status + " " + result)
                }
              }
            } catch (error) {
              console.error(`Error fetching data - caught: ${error.message}`);
            } 
        };
  
        console.log("on 2nd render", isFirstRender.current)
        if(isFirstRender.current && isApprovedRef.current === false){
          console.log("Is this approved?", isApproved)
          console.log("initial render should show true", isFirstRender.current)
          //on a - CONTINUE this will come in true and not call fetchDataUpdate
          if(!isContinue) {
            if(stepsProductionObj[stepsProductionObj.length - 1].linkStepStatusId !== 2) {
              console.log("NOT CONTINUE ON REFRESH ALREADY UPDATED LINKSTEP STATUS ID")
              console.log("NOT CONTINUE ON REFRESH ALREADY UPDATED LINKSTEP STATUS ID", stepsProductionObj[0].linkStepStatusId)
              getAuthToken();
              TokenValid.current && fetchDataUpdate();
              console.log("not continue")
            }
          }
          else {
            isFirstRender.current = false
            dispatch(continueFalse())
            console.log("continue don't update paymentAuth")
          }
        }
        else {
          if (isApproved || isApprovedRef.current) {
            console.log("Is this approved?", isApproved)
            console.log("Card approved: ", message);
            getAuthToken();
            TokenValid.current && fetchDataApproved();
    
          } else if (isDecline || isDeclinedRef.current) {
            setLoading(false);
            console.log("Card Declined: ", message);
          } else if (isError || isErrorRef.current) {
            setLoading(false)
            console.log("Card Error message: ", message);
          }
          
        }
  
        //SIGNING LISTENER
        if (!window.addEventListener) {
          console.log("hello");
        } else {
          window.addEventListener("message", eventHandler, false);
        }
      }
      
    })
    
  }, [message, show]);


  //Logic for Payment Gate
  // endpoint -> api/converge (send query only with amount)
  // convert this function to call back - end 

  const initiateCheckoutJS = async () => {
    try {
      const tokenParam = new URLSearchParams();
      tokenParam.append("amount", cost)
      const resp = await fetch(`${billingApiUrl}/api/converge?` + tokenParam.toString(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "69420",
          "x-api-key": API_KEY,
          "Authorization": "Bearer" + " " + cookies.get('jwt_authorization')
        },
        
      });
      if (resp.ok) {
        console.log("received token");
        const tokenResult = await resp.text();
        console.log("token result", tokenResult)
        return tokenResult;
      } 
      else if(!resp.ok) {
        setLoading(false);
        //document.body.style.backgroundColor = "#551A8B";
        if(resp.statusText) {
          setError(resp.status + " " + resp.statusText)
        }
        else {
          const result = await resp.text()
          setError(resp.status + " " + result)
        }
      }
    
    } catch (error) {
      console.log(error);
    }
  };

  // const initiateCheckoutJS = async () => {
  //   try {
  //     const response = await fetch(`${baseUrlConverge}/transaction_token`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded",
  //       },
  //       body: new URLSearchParams({
  //         ssl_merchant_id: SSL_MERCHANT_ID,
  //         ssl_user_id: SSL_USER_ID,
  //         ssl_pin: SSL_PIN,
  //         ssl_transaction_type: "ccsale",
  //         ssl_amount: cost,
  //       }),
  //     });
  //     const data = await response.text();
  //     console.log(data);
  //     return data;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  var callback3D = {
    onError: function (error) {
        showResult("error", error);
        handleShow();
        setIsError(true);
        isErrorRef.current = true;
        convergeLogApi(error);
        console.log("Error obj in callback 3D", error);
        setLoading(false);
    },
    onDeclined: function (response) {
        showResult("declined", JSON.stringify(response));
        handleShow();
        setIsDecline(true);
        isDeclinedRef.current = true;
        convergeLogApi(response);
        setLoading(false);
    },
    onApproval: function (response) {

        showResult("approval", JSON.stringify(response));
    },
    onCancelled: function () {
        showResult("cancelled", "");
    },
    onThreeDSecure2: function (response) {
        console.log("3ds2 token response:");
        console.log(response);
        if (response.ssl_3ds2_token) {
            const efsToken = response.ssl_3ds2_token;
            console.log("THIS IS THE TOKEN", efsToken)
            handleAllInOne(efsToken)
            
        } else {
            console.log("ERROR")
        }
    }
};
  
  async function getEfsToken(initToken) {

    console.log("INSIDE GET EFSTOKEN", initToken)

      var paymentDataToken = {
          ssl_txn_auth_token: initToken
      };
      const result = window.ConvergeEmbeddedPayment.getEFSToken(paymentDataToken, callback3D);
      return false;
  }

  async function pay(token, authValue, dsTransId, threeDSServerTransId, transStatus, transStatusReason, msgVersion, eciVal, programProtocol) {
    const names = cardData.FullName.split(" ");
    const fname = names[0];
    const lname = names[1];

    console.log("token", token)
    console.log("authValue", authValue)
    console.log("dsTransId", dsTransId)
    console.log("threeDSServerTransId", threeDSServerTransId)
    console.log("transStatus", transStatus)
    console.log("transStatusReason", transStatusReason)
    console.log("msgVersion", msgVersion)
    console.log("eciVal", eciVal)
    console.log("programProtocol", programProtocol)

    const paymentData = {
      ssl_cardholderName: cardData.FullName,
      ssl_txn_auth_token: token,
      ssl_card_number: cardData.CardNumber,
      ssl_exp_date: cardData.ExpirationDate,
      ssl_get_token: "y",
      ssl_add_token: "y",
      ssl_first_name: fname,
      ssl_last_name: lname,
      ssl_cvv2cvc2: cardData.Cvc,
      ssl_avs_address: cardData.BillingAddress,
      ssl_avs_zip: cardData.BillingZip,
      ssl_city: cardData.BillingCity,
      //ssl_state: cardData.BillingState,
      ssl_program_protocol: programProtocol,
      ssl_dir_server_tran_id: dsTransId,
      ssl_eci_ind: eciVal,
      ssl_3dsecure_value: authValue,
      ssl_3ds_message_version: msgVersion,
      ssl_3ds_trans_status: transStatus,
      ssl_3ds_trans_status_reason: transStatusReason,
      ssl_3ds_server_trans_id: threeDSServerTransId,
      ssl_merchant_txn_id: "merchanttxnid",
    };
    const callback = {
      onError: function (error) {
        showResult("error", error);
        handleShow();
        setIsError(true);
        isErrorRef.current = true;
        convergeLogApi(error);
        setLoading(false);
        console.log("Error obj", error);
        // showResult("error", error);
        
      },
      onDeclined: function (response) {
        showResult("declined", response);
        handleShow();
        setIsDecline(true);
        isDeclinedRef.current = true;
        console.log("Is Declined", isDecline);
        console.log("is Decline Ref", isDeclinedRef.current);
        console.log("Response from decline", response);
        convergeLogApi(response);
        console.log("Result Message=" + response["ssl_result_message"]);
        setLoading(false);
        
      },
      onApproval: function (response) {
        setIsApproved(true);
        isApprovedRef.current = true;
        console.log("Is this approved?", isApproved)
        console.log("Is this approved?", isApprovedRef)
        console.log("Approval Code=" + response["ssl_approval_code"]);
        transId.current = response.ssl_txn_id
        dispatch(updateTransId({ssl_txn_id: transId.current}));
        showResult("approval", response); 
      },
    };
    const result = window.ConvergeEmbeddedPayment.pay(paymentData, callback);
    return false;
  }

  function showResult(status, msg) {
    setMessage(msg);
    const upper = status[0].toUpperCase();
    const statusUpper = upper + status.substring(1) + " due to the following reason:";
    setMessageStatus(statusUpper);
    console.log("Status", status);
    console.log("Message", msg);
  }

  function getEFSEci(eci) {
    if (eci === '01' || eci === '05') {
        return '5';
    } else if (eci === '02' || eci === '06') {
        return '6';
    } else {
        return '7';
    }
};

async function handleAllInOne(token) {
    
  console.log("token-efs inside all-in-one", token)
  var sdk = new window.Elavon3DSWebSDK({ baseUrl: base3dSdkUrl, token: token, el: 'holder' });

  const expDateStr = cardData.ExpirationDate + ""
  console.log("EXP-Date", expDateStr);
  const expDateMonth = expDateStr.substring(0, 2)
  console.log("month", expDateMonth)
  const expDateYear = expDateStr.substring(2, 4)
  console.log("Year", expDateYear)
  const expDateResult = expDateYear + expDateMonth

  console.log("SDK", sdk)
  var request = {
      //challengeIframeElement: document.getElementById('holder'),
      purchaseAmount: cost,
      purchaseCurrency: "840",
      purchaseExponent: "2",
      //purchaseDate : "20200429112659",
      acctNumber: cardData.CardNumber,
      cardholderName: cardData.FullName,
      email: payorEmail || patientObj.email,
      cardExpiryDate: expDateResult,
      messageCategory: "01",
      transType: "01",
      threeDSRequestorAuthenticationInd: "01",
      challengeWindowSize: "02",
      displayMode: "lightbox"
  };

  sdk.web3dsFlow(request).then(function success(response) {
      const eciParam = getEFSEci(response.eci);
      const programProtocol = 2;
      console.log("ECI Param", eciParam);
      // document.getElementById('ssl_eci_ind').value = getEFSEci(response.eci);
      // document.getElementById('ssl_program_protocol').value = 2;
      console.log("INIT JS TOKEN", initElavonToken.current);
      if (response.authenticationValue) {
        console.log("RESPONSE", response.authenticationValue)
          //document.getElementById('ssl_3dsecure_value').value = response.authenticationValue;
      }
      if (response.dsTransID) {
        console.log("RESPONSE", response.dsTransID)
          //document.getElementById('ssl_dir_server_tran_id').value = response.dsTransID;
      }
      if (response.threeDSServerTransID) {
        console.log("RESPONSE", response.threeDSServerTransID) 
          //document.getElementById('ssl_3ds_server_trans_id').value = response.threeDSServerTransID;
      }
      if (response.transStatus) {
        console.log("RESPONSE", response.transStatus)
          //document.getElementById('ssl_3ds_trans_status').value = response.transStatus;
      }
      if (response.transStatusReason) {
        console.log("RESPONSE", response.transStatusReason)
          //document.getElementById('ssl_3ds_trans_status_reason').value = response.transStatusReason;
      }
      if (response.messageVersion) {
        console.log("RESPONSE", response.messageVersion)
          //document.getElementById('ssl_3ds_message_version').value = response.messageVersion;
      }
      const authTransStatuses = ["R", "U", "N"]
      if(authTransStatuses.includes(response.transStatus)) {
        console.log("Don't process payment. use another card", response.transStatusReason)
        console.log("Response from reject", response);
        setIsError(true);
        isErrorRef.current = true;
        convergeLogApi(response);
        setMessage(t('paymentAuthorizationUnableToAuthenticateMsg'))
        handleShow();
        setLoading(false);
        
      }
      else {
        pay(initElavonToken.current, response.authenticationValue, response.dsTransID, response.threeDSServerTransID, response.transStatus, response.transStatusReason, response.messageVersion, eciParam, programProtocol)
      }

  },function error(response) {
      setIsError(true);
      isErrorRef.current = true;
      console.log("Error " + response);
      let res = JSON.stringify(response);
      const errorObj = JSON.parse(res)
      console.log("Error errorObj parse", errorObj);
      convergeLogApi(errorObj);
      console.log("Error errorObj Message parsed", errorObj.message);
      setMessage(errorObj.message);
      handleShow();
      setLoading(false);
  
       
      
      //document.getElementById('ssl_eci_ind').value = "7";

  });
}

const expMessage = useRef("");
const cvcMessage = useRef("");

const monthFormat = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]
const allowedCharacters = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

const handleChangeCvc = (text) => { 
  // Allow only numbers 
  console.log("TEXT in handle change", text);
  const numericValue = text.replace(/[^0-9]/g, ""); 
  //setInputValue(numericValue); 
  console.log("numeric value in handlechange", numericValue);
  setCardData({ ...cardData, Cvc: numericValue});
}

const handleChangeExp = (text) => { 
  // Allow only numbers 
  console.log("TEXT in handle change", text);
  const numericValue = text.replace(/[^0-9]/g, ""); 
  //setInputValue(numericValue); 
  console.log("numeric value in handlechange", numericValue);
  setCardData({ ...cardData, ExpirationDate: numericValue});
}

const handleChangeCard = (text) => { 
  // Allow only numbers 
  console.log("TEXT in handle change", text);
  const numericValue = text.replace(/[^0-9]/g, ""); 
  //setInputValue(numericValue); 
  console.log("numeric value in handlechange", numericValue);
  setCardData({ ...cardData, CardNumber: numericValue});
}

const cvcValidation = (cvcParam) => {
  if(cvcParam) {
    let cvcCharArr = cvcParam.split('');
    console.log("CVC char arr", cvcCharArr);
    for(let i = 0; i < cvcCharArr.length; i++) {
      if(!allowedCharacters.includes(cvcCharArr[i])) {
        console.log("There is a special character");
        cvcMessage.current = t('paymentAuthorizationValidationNoSpecialCharacters');
        return true;
      }
    }
    if(cvcParam.length >  0 && cvcParam.length < 3) {
      cvcMessage.current = t('paymentAuthorizationFormFeedBackCvc');
      console.log("here is the cvc message", cvcMessage.current);
      return true;
    }
  }
}

const charactersNotAllowed = (expDate) => {
  if(expDate) {
    const currentYear = (new Date().getFullYear()) % 100;
    const currentMonth = new Date().getMonth() + 1;
    const monthArr = [];
    const yearArr = [];
    console.log("Current Year", currentYear);

    let expDateCharArr = expDate.split('');
    console.log("exp date param", expDate);
    console.log("exp date char", expDateCharArr);
    for(let i = 0; i < expDateCharArr.length; i++) {
      if(!allowedCharacters.includes(expDateCharArr[i])) {
        console.log("There is a special character");
        expMessage.current = t('paymentAuthorizationValidationNoSpecialCharacters');
        return true;
      }
    }
    for(let i = 0; i < 2; i++) {
      monthArr.push(expDate[i]);
    }
    const monthResult = monthArr[0] + monthArr[1];
    if(monthArr.length === 2 && !monthFormat.includes(monthResult)) {
      expMessage.current = t('paymentAuthorizationExpDateValidationMonth');
      return true;
    }
    if(expDate.length === 4) {
      for(let i = 2; i < expDate.length; i++) {
        yearArr.push(expDate[i]);
      }
    }
    console.log("Year Array", yearArr);
    if(yearArr.length < 2) {
      expMessage.current = ""
      return true;
    }
    else if(yearArr.length === 2) {
      console.log("Year array length 2")
      console.log("Month inside the year condition", monthArr);
      const yearResult = yearArr[0] + yearArr[1];
      const yearNum = parseInt(yearResult);
      if(yearNum < currentYear) {
        console.log("Year less than 24")
        expMessage.current = t('paymentAuthorizationExpDateValidationYear')
        console.log("EXP Message", expMessage.current);
        return true;
      }
      else if(yearNum === currentYear) {
        //check month
        console.log("Month in current year", currentMonth)
        console.log("Month entered", monthResult)
        console.log("Month in 0 position", monthArr[0])
        if(monthArr[0] === 0) {
          console.log("Month has 0")
          if(monthArr[1] < currentMonth) {
            console.log("current month less than monthArr[1]")
            expMessage.current = t('paymentAuthorizationExpDateValidationDate');
            return true;
          }
        }
        else {
          if(monthResult < currentMonth) {
            expMessage.current = t('paymentAuthorizationExpDateValidationDate');
            return true;
          }
        }
        
        }
      }
    }
  }

  

const handlePayment = async (e) => {
  e.preventDefault();
  isClicked.current = true;
  console.log("Is Clicked", isClicked.current);
  console.log("this is the card data", cardData);
  setLoading(true);
  getAuthToken();
  console.log("Right before initiate checkout JS is token Valid", TokenValid.current);
  const resToken = TokenValid.current && await initiateCheckoutJS();
  console.log("Initiate JS Called and finished");
  initElavonToken.current = resToken;
  console.log("resToken", initElavonToken)
  getAuthToken();
  TokenValid.current && getEfsToken(resToken);
};


  const handleAcceptTerms = () => {
    setIsAgreed(!isAgreed);
    console.log("agreed", isAgreed)
  }

  return (
    <>
      {!patientObj || !stepsProductionObj ?
        <>
        <Error401 />
        </>
        :
      <>
      {loading && (
        <div className="spinner-cont">
          <Spinner className="spinner" animation="border" role="status" style={{ width: "4rem", height: "4rem" }}>
            <span className="visually-hidden">Loading</span>
          </Spinner>
        </div>
      )}
      {!error ? 
      <>
       <AppLayout />
      <div className="payAuth-title">
        <h1>{t('paymentAuthorizationTitle')}</h1>
      </div>
      {!patient ?
     <h1>Loading...</h1>
        : 
      <Container className="payment-form-container">
        <Form onSubmit={handlePayment}>
          <Container className="container-payform-top">
            <div className="payinfo-date-row">
              <Row className="date-row-item">
                <Col>
                  <p>{personToPay}</p>
                </Col>
                <Col className="date-item">
                  <p>{t('paymentAuthorizationPayDate')}</p>
                </Col>
              </Row>
              <div className="date-location-item">
                <p>{date}</p>
              </div>
            </div>
            {/* {patientObj.paymentSource === "1"
            ?
            <Form.Group className="top-field" controlId="formBasicFullName">
              <Form.Label className="form-label">{t('paymentAuthorizationFormName')}</Form.Label>
              <Form.Control
                type="text"
                value={cardData.FullName}
                readOnly
                disabled
                onChange={(e) => {
                  setCardData({ ...cardData, FullName: e.target.value });
                }}
                required
              />
            </Form.Group>
            : */}
            <Form.Group className="top-field" controlId="formBasicFullName">
              <Form.Label className="form-label">{t('paymentAuthorizationFormName')}</Form.Label>
              <Form.Control
                type="text"
                value={cardData.FullName}
                onChange={(e) => {
                  setCardData({ ...cardData, FullName: e.target.value });
                }}
                required
              />
            </Form.Group>
            {/* } */}
            <Form.Group className="top-field" controlId="formBasicCardNumber">
              <Form.Label className="form-label">{t('paymentAuthorizationFormCardNumber')}</Form.Label>
              <Form.Control className={`card-type ${res}`}
                inputMode="numeric"
                placeholder="XXXXXXXXXXXXXXXX"
                maxLength={16}
                value={cardData.CardNumber}
                onKeyDown={e => symbolsNotAllowed.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  handleChangeCard(e.target.value);
                }}
                required
              />
            </Form.Group>
          </Container>
          <Container className="container-card-info">
            <Row>
              <Col>
                <Form.Group className="top-field" controlId="formBasicCardExpiration">
                  <Form.Label className="form-label">{t('paymentAuthorizationFormExpDate')}</Form.Label>
                  <Form.Control
                    inputMode="numeric"
                    maxLength={4}
                    minLength={4}
                    placeholder="MMYY"
                    value={cardData.ExpirationDate}
                    onKeyDown={e => symbolsNotAllowed.includes(e.key) && e.preventDefault()}
                    onChange={(e) => {
                      handleChangeExp(e.target.value);
                    }}
                    required
                    isInvalid={cardData.ExpirationDate.length > 0 && charactersNotAllowed(cardData.ExpirationDate)}
                  />
                  <Form.Control.Feedback type="invalid">
                  <Toast bg="danger">
                      <Toast.Body>
                        <p style={{ color: "white"}}>{t('paymentAuthorizationExpDateValidationMainMessage')} <br/> {expMessage.current}</p>
                      </Toast.Body>
                    </Toast>
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="top-field" controlId="formBasicCVC">
                  <Form.Label className="form-label">{t('paymentAuthorizationFormCvc')}</Form.Label>
                  <Form.Control
                    inputMode="numeric"
                    maxLength={4}
                    minLength={3}
                    value={cardData.Cvc}
                    onKeyDown={e => symbolsNotAllowed.includes(e.key) && e.preventDefault()}
                    onChange={(e) => {
                      handleChangeCvc(e.target.value)
                    }}
               
                    isInvalid={cvcValidation(cardData.Cvc)}
                  />
                  <Form.Control.Feedback type="invalid">
                  <Toast bg="danger">
                      <Toast.Body>
                        <p style={{ color: "white"}}>{cvcMessage.current}</p>
                      </Toast.Body>
                    </Toast>
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="top-field" controlId="formBasicBillingZip">
                  <Form.Label className="form-label">{t('paymentAuthorizationFormZip')}</Form.Label>
                  <Form.Control
                    type="text"
                    value={cardData.BillingZip}
                    onChange={(e) => {
                      setCardData({ ...cardData, BillingZip: e.target.value });
                    }}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
          </Container>

          <Container>
            <Form.Group className="top-field" controlId="formBasicBillingAddress">
              <Form.Label className="form-label">{t('paymentAuthorizationFormAddress')}</Form.Label>
              <Form.Control
                type="text"
                value={cardData.BillingAddress}
                onChange={(e) => {
                  setCardData({ ...cardData, BillingAddress: e.target.value });
                }}
              required
              />
            </Form.Group>

            <Row>
              <Col>
            <Form.Group className="top-field" controlId="formBasicCity">
              <Form.Label className="form-label">{t('paymentAuthorizationFormCity')}</Form.Label>
              <Form.Control
                type="text"
                value={cardData.BillingCity}
                onChange={(e) => {
                  setCardData({ ...cardData, BillingCity: e.target.value });
                }}
              
              />
            </Form.Group>
            </Col>
            <Col>
            <Form.Group className="top-field" controlId="formBasicState">
              <Form.Label className="form-label">{t('paymentAuthorizationFormState')}</Form.Label>
              <Form.Select className="dropdown-states"
                defaultValue={cardData.BillingState}
                onChange={(e) => {setCardData({ ...cardData, BillingState: e.target.value })}}>
                  {stateData.map((item, key) => {
                    return (
                    <option value={item.name} key={key}>{item.Abbreviation}</option>
                    )})
                  }
              </Form.Select>
            </Form.Group>
            </Col>
            {patientObj.paymentSource.toString() === "2" 
              ?
              <Form.Group className="top-field" controlId="formEmail">
              <Form.Label className="form-label">{t('paymentAuthorizationFormPayorEmail')}</Form.Label>
              <Form.Control
                type="email"
                onChange={(e) => setPayorEmail(e.target.value)}
              />
            </Form.Group>
              :
              <div> 
              </div>
            }
            </Row>
            {patientObj.paymentSource.toString() === "2" 
              ?
              <>
              {/* UPATED LOGIC PER MANAGEMENT TO ONLY SHOW A CC AUTH FORM WHEN THERE IS A PAYOR */}
              <Container className="credit-card-iframe-container">
                <iframe src={stepsProductionObj[stepsProductionObj.length - 1].documentUrl} title="payment" width="100%" height="400"></iframe>
                </Container> 
                
              {/* <Container className="payment-form-container">
              
                <div className="pay-para-div">
                <p>{t('paymentAuthorizationPayorParagraph1')} <strong>{cardData.FullName}</strong> {t('paymentAuthorizationPayorParagraph2')} <strong>${cost}.00</strong> {t('paymentAuthorizationPayorParagraph3')} <strong>{t('paymentAuthorizationPayorParagraph4')}</strong>{t('paymentAuthorizationPayorParagraph5')}</p>
                </div>
                </Container>
                <div className="payauth-checkbox">
                <Form.Check
                label={t('paymentAuthorizationFormAgreementBtn')}
                name="agree"
                type="checkbox"
                value={isAgreed}
                onChange={() => handleAcceptTerms(!isAgreed)}
                >       
                </Form.Check>
              
                </div>  */}
                </>
                : 
                <div>
                </div>
                // <Container className="credit-card-iframe-container">
                // <iframe src={stepsProductionObj[stepsProductionObj.length - 1].documentUrl} title="payment" width="100%" height="400"></iframe>
                // </Container> 
                }
                  </Container> 
          <div className="info-btnAuth-location">
            {/* disabled must be false to enable */}
            <Button disabled={
              patientObj.paymentSource.toString() === '2' 
              ?
              !isSigned || 
               !cardData.FullName || cardData.CardNumber.length < 13 || cardData.ExpirationDate.length < 4 || !cardData.BillingZip || !cardData.BillingAddress
               || cardData.Cvc.length < 3 || !cardData.BillingCity || !payorEmail || charactersNotAllowed(cardData.ExpirationDate) || cvcValidation(cardData.Cvc)
               || isClicked.current
              // isAgreed ||
              //  !cardData.FullName || cardData.CardNumber.length < 13 || cardData.ExpirationDate.length < 4 || !cardData.BillingZip || !cardData.BillingAddress
              //  || cardData.Cvc.length < 3 || !cardData.BillingCity
              : 
              // !isSigned ||
               !cardData.FullName || cardData.CardNumber.length < 13 || cardData.ExpirationDate.length < 4 || !cardData.BillingZip || !cardData.BillingAddress
               || cardData.Cvc.length < 3 || !cardData.BillingCity || charactersNotAllowed(cardData.ExpirationDate) || cvcValidation(cardData.Cvc) || isClicked.current
              } className="btn-payAuth" type="submit">
              {t('paymentAuthorizationBtn')} 
            </Button>
          </div>
          <p className="d-flex justify-content-center">{t('paymentAuthorizationEncryptionMsg')}</p>
        </Form>
      </Container>}
      {isDecline && (
        <>
        <ModalComponent show={show} handleClose={handleClose} message={message}/>
        {/* <Container className="decline-message">
          <Card className="decline-card"> 
            <h5>{t('paymentAuthorizationDeclineHeader')}</h5>
            <p>{messageStatus}</p>
            <p>{message.errorMessage}</p>
          </Card>
        </Container> */}
        </>
      )}
      {isError && (
        <>
        <ModalComponent show={show} handleClose={handleClose} message={message}/>
        </>
      )}
      </>
      :
      <Container className="error-display-container">
      <h3>Error {error}</h3>
      </Container>}    
    </>}
    </>
  );
}
